@import 'components/variables';
@import 'components/calc-container';
@import 'components/purple-calc';
@import 'components/yellow-calc';
@import 'components/aqua-calc';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font-p;
    background-color: $color-blue;
}

header {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 5vh;
    
    h1 {
        position: absolute;
        display: inline;
        left: auto;
        right: 15px;
        top: 0;
        
        padding: 5px 10px 5px 5px;
        border: 2px solid #FFF;
        border-bottom: 5px solid #FFF;
        
        font-family: $font-title;
        font-size: 15px;
        font-weight: 500;
        color: $color-white;
    }
}

footer {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
 }

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 8vh;
    width: 30%;
  
    position: absolute;
    top: auto;
    bottom: 0;    
}

.social-media__button {
    padding: 4px;
  
    a img {
      width: 20px;
      height: 20px;
    }
  }

#social-media__button--p {
    font-size: 11px;
    font-family: $font-title;
    text-align: center;
    font-weight: 200;
    width: auto;
    margin: 0;
    color: $color-white;
}
@import 'variables';

.yellow-calc {

    grid-area: calc;
    align-self: start;
    justify-self: start;

    display: grid;
    grid: repeat(4, 70px) / 70px repeat(2, 50px);
    grid-template-areas: 
    "lcd lcd lcd"
    "onoff - -"
    "copy - -"
    "c - -"
    ;
    column-gap: 15px;
    row-gap: 15px;
    align-items: center;
    justify-items: start;
    justify-content: center;

    width: auto;
    margin: 5px;
    padding: 15px 10px 20px 15px;
    z-index: 2;

    background-color: $color-soft-yellow;
    border-radius: 25px 0px 0px 25px;
}


.yellow-calc__button {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 90%;
    height: 90%;
    
    background-color: $color-yellow ;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
    font-family: $font-p;
    font-size: 20px;
    font-weight: 500;
    color: $color-white;
    cursor: pointer;
}

#lcd { 
    width: 85%;
    height: 70%;
    grid-area: lcd; 
    justify-content: space-between;
    padding-right: 1rem;
    
}
#lcd-symbol {
    padding-left: 1rem;
    padding-right: 0.3rem;
}

#button__on-off { grid-area: onoff; }

#button__copy { grid-area: copy; }

#button__c { grid-area: c; }
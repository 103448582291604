@use 'variables';

.purple-calc {
    grid-area: calc;
    align-self: end;
    justify-self: center;

    display: grid;
    grid: repeat(4, 60px) / repeat(3, 60px);
    align-items: center;
    justify-items: center;
    justify-content: center;

    width: auto;
    margin: 5px 5px 25px 5px;
    padding: 10px;
    background-color: variables.$color-purple;

    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    z-index: 3;
}


.purple-calc__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 80%;
    height: 80%;

    background-color: variables.$color-soft-purple;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    font-family: variables.$font-p;
    font-size: 25px;
    font-weight: 500;
    color: variables.$color-white;

    cursor: pointer;
}
//Color Variables

$color-yellow: #E0B51B ; 
$color-soft-yellow: #F9DB72;
$color-green: #4BA66F;
$color-soft-green: #5DDE91;
$color-purple: #724AE4;
$color-soft-purple: #9D83E8;
$color-blue: #33A09A;
$color-white: #FFFFFF;

// Fonts Variables

$font-title: 'Poppins', sans-serif;
$font-p: 'Roboto', sans-serif;
@use 'variables';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 87vh;
    
    margin: 0;
    padding: 0;
}

.calculator {
    /* display: flex;
    justify-content: center;
    align-items: center;
    position: relative;  */

    display: grid;
    justify-items: center;
    align-items: center;
    grid: 100% / 100%;
    grid-template-areas: 'calc';
    width: 400px;
    max-width: 600px;
    height: 380px;
}

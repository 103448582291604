@use 'variables';

.aqua-calc {
    grid-area: calc;
    align-self: center;
    justify-self: end;

    display: grid;
    grid: repeat(4, 70px) / repeat(2, 70px);
    grid-template-areas: 
    'exp plus'
    '- less'
    '- multi'
    '- div'
    ;
    column-gap: 15px;
    row-gap: 15px;
    align-items: center;
    justify-items: end;
    justify-content: center;
    z-index: 2; /* CAMBIAR A 1 AL FINALIZAR */

    width: auto;
    margin: 5px;
    margin-bottom: 15px;
    padding: 15px 15px 20px 10px;

    background-color: variables.$color-soft-green;
    border-radius: 0px 25px 25px 0px;
}

#button__exponent { grid-area: exp; }
#button__add { grid-area: plus; }
#button__subtraction { grid-area: less; }
#button__multiply { grid-area: multi; }
#button__divide { grid-area: div; }

.aqua-calc__button {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 90%;
    height: 90%;
    
    background-color: variables.$color-green ;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    
    font-family: variables.$font-p;
    font-size: 20px;
    font-weight: 500;
    color: variables.$color-white;

    cursor: pointer;
}